// Menu toggle
document.addEventListener('DOMContentLoaded', () => {
    const uxdlucas_menu = document.querySelector('.icon-hamburger') as HTMLElement;
    uxdlucas_menu?.addEventListener("click", () => {
        document.body.classList.toggle('menu-open');
    });
});

// Add class on scroll
window.addEventListener('scroll', () => {
    if (window.scrollY > 100) {
        document.body.classList.add('scroll-down');
    } else {
        document.body.classList.remove('scroll-down');
    }
});

// Dropdown menu mobile
document.addEventListener('DOMContentLoaded', () => {
    if (window.innerWidth < 992) {
        const items = document.querySelectorAll('.menu-item-has-children > a');
        items.forEach((item) => {
            // Definisce il listener come EventListener per evitare problemi di tipo
            const clickListener: EventListener = (e: Event) => {
                const event = e as MouseEvent; // Usa un type assertion qui
                const rect = item.getBoundingClientRect();
                const clickX = event.clientX;
                const threshold = rect.right - 40;

                if (clickX > threshold) {
                    e.preventDefault();
                    let parentElement = item.parentNode as HTMLElement;
                    parentElement.classList.toggle('open-dropdown');
                }
            };

            item.addEventListener("click", clickListener);
        });
    }
});



// Accordion
let accordionItems = document.querySelectorAll('.accordion .wp-block-column > h3');
accordionItems.forEach((item) => {
    item.addEventListener("click", (e) => {
        e.preventDefault();
        let siblingElement = item.nextElementSibling as HTMLElement;
        siblingElement.classList.toggle('open-accordion');
    });
});

// Intersection Observer API
const observerOptions: IntersectionObserverInit = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3
};

function observerCallback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.classList.add('fadeIn');
        } else {
            // Fade out not needed when not in view
            // entry.target.classList.replace('fadeIn', 'fadeOut');
        }
    });
}

const fadeElms = document.querySelectorAll('.fade');
const observer = new IntersectionObserver(observerCallback, observerOptions);
fadeElms.forEach(el => observer.observe(el));

// JS scroll to
document.querySelectorAll('.scroll a[href^="#"]').forEach(elem => {
    elem.addEventListener('click', e => {
        e.preventDefault();
        const href = elem.getAttribute('href');
        if (!href) return;

        const block = document.querySelector(href) as HTMLElement;
        if (!block) return;

        const offsetAttr = elem.getAttribute('data-offset');
        let offset = 0;
        if (offsetAttr && !isNaN(Number(offsetAttr))) {
            offset = parseInt(offsetAttr);
        }

        const bodyOffset = document.body.getBoundingClientRect().top;
        window.scrollTo({
            top: block.getBoundingClientRect().top - bodyOffset + offset,
            behavior: 'smooth'
        });
    });
});

// icon burger gestore aria-expand
document.addEventListener('DOMContentLoaded', () => {
    const hamburgerIcon = document.querySelector('.icon-hamburger') as HTMLButtonElement;

    hamburgerIcon?.addEventListener('click', function() {
        const menu = document.getElementById('main-menu');
        if (!menu) return;

        const isExpanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', String(!isExpanded));
    });
});
